.loading-full-screen:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #CE3E72;
    border-color: #CE3E72 transparent #CE3E72 transparent;
    animation: loading-full-screen 1.5s linear infinite;
}
@keyframes loading-full-screen {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
