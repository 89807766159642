@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');

body {
  width: 100%;
  background-color: white !important;
  font-family: 'Nunito Sans', sans-serif !important;
  @apply font-body;
  @apply text-primary-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
  font-family: 'Nunito Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:focus {
  box-shadow: none !important;
}
/*****************REACT-BOOTSTRAP***************************/

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  width: calc(100vw - 30px);
  max-width: 549px;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }
  /* fix bug of react-bootstrap accordion not showing body of items */
  .collapse {
    visibility: visible;
  }
}

/* add font family */
@layer base {
  @font-face {
    font-family: Proxima Nova;
    font-weight: 400;
    src: url(/fonts/proxima-nova/400-regular.woff) format('woff');
  }
  @font-face {
    font-family: Proxima Nova;
    font-weight: 500;
    src: url(/fonts/proxima-nova/500-medium.woff) format('woff');
  }
  @font-face {
    font-family: Nunito Sans;
    font-weight: 600;
    src: url(../fonts/nunito-sans/nunito-sans-latin-600.woff) format('woff');
  }
}

:root {
  color-scheme: light only;
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  /*bg colors*/
  --rotoplas-bg-color-blue-light: #8dc6e8;
  --rotoplas-bg-color-gray-light: #f7f7f7;

  /*colors*/
  --rotoplas-color-white: #fff;
  --rotoplas-color-blue-dark-1: #000255;
  --rotoplas-color-black: #231f20;
  --rotoplas-color-blue-dark-2: #002554;
  --rotoplas-color-gray-dark: #424242;
  --rotoplas-color-gray-light: #878787; /*placeholder*/
  --rotoplas-color-blue: #165eeb;
  --rotoplas-color-gray-light-2: #eaeaea;
  --rotoplas-color-blue-2: #165eeb;

  /* buttons */
  /* --rotoplas-button-border-color: #00aeef; */

  /*dropdowns*/
  --rotoplas-dropdrown-border-color: #e5e5e5;

  /*navbar*/
  --rotoplas-navbar-border-color: #d8d8d8;
}

.product-detail-grid-card-1 {
  width: 200px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 200px 50px 50px 30px 25px 43px;
  grid-template-areas:
    'picture picture'
    'pictures pictures'
    'title title'
    'sku rating'
    'price .'
    'buy buy';
}

/* .product-detail-grid-card-1 > div {
  border: 1px solid red;
}

.product-detail-grid-list-1 > div {
  border: 1px solid red;
} */

.product-detail-grid-list-1 {
  width: 100%;
  display: grid;
  grid-template-columns: 144px 50px auto 186px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'picture pictures title buy'
    'picture pictures price .'
    'picture pictures sku .'
    'picture pictures rating .'
    'picture pictures description description'
    'picture pictures description description';

  padding-bottom: 40px;
  border-bottom: 1px solid gray;
}

.hide {
  display: none !important;
}

.hide-lg {
  display: none;
}
.hide-md {
  display: none;
}

/*hide description*/
.grid-card-1-description {
  grid-area: description;
  display: block;
}

.grid-card-1-picture {
  grid-area: picture;
}

.grid-card-1-pictures {
  grid-area: pictures;
  display: flex;
}

.grid-card-1-title {
  grid-area: title;
  overflow: hidden;
  /* width: 100px; */
  max-width: 100%;

  text-overflow: ellipsis;
}

.grid-card-1-sku {
  grid-area: sku;
}

.grid-card-1-rating {
  grid-area: rating;
  /* material ui rating bug */
  z-index: -1;
}

.grid-card-1-price {
  grid-area: price;
}

.grid-card-1-buy {
  grid-area: buy;
}

/*--------------------------------------------Cart Grid--------------------------------------------------------------*/
.cart-headers {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1.2fr 1.2fr 1fr;
  grid-template-areas: 'image-header product-name-header price-header quantity-header subtotal-header';
}

.image-header {
  grid-area: image-header;
}

.product-name-header {
  grid-area: product-name-header;
}

.price-header {
  grid-area: price-header;
}

.quantity-header {
  grid-area: quantity-header;
}

.subtotal-header {
  grid-area: subtotal-header;
}

.cart-grid {
  display: grid;
  width: 100%;
  height: 200px;
  grid-template-rows: 0.5fr 1fr;
  grid-template-columns: 1fr 1fr 1.2fr 1.2fr 1fr;
  grid-template-areas:
    'image-grid product-name price quantity subtotal'
    'image-grid delete-icon  . . .';
}

/* .cart-grid * {
  border: 1px solid red;
} */

.image-grid {
  grid-area: image-grid;
  display: block;
}

.product-name {
  grid-area: product-name;
  display: block;
  margin-left: 10px;
}

.price {
  grid-area: price;
  display: block;
}

.quantity {
  grid-area: quantity;
  display: block;
  margin-left: 0px;
}

.subtotal {
  grid-area: subtotal;
  display: block;
  margin-left: 0px;
}

.delete-icon {
  grid-area: delete-icon;
  margin-left: 10px;
}

/* .cart-grid-mobile * {
  border: 1px solid red;
} */

.cart-grid-mobile {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr 70px 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas:
    'image-grid product-name delete-icon'
    'image-grid subtotal  . '
    'image-grid quantity .';
}

@media screen and (max-width: 768px) {
  .cart-headers {
    display: none;
  }
  .price {
    display: none;
  }

  .subtotal {
    margin-left: 10px;
  }
  .quantity {
    margin-left: 10px;
  }
}

/*-------------------------------------------------------------------------------------------------------------------*/

/*-----------------------------------------------BEBBIA--------------------------------------------------------------------*/

.bebbia-form-input-error {
  background-color: #fef0f2;
  border-color: #f42f43 !important;
}

.bebbia-form-input-error::placeholder {
  color: #f42f43;
}

/* https://react-bootstrap.netlify.app/docs/layout/breakpoints/ */

.bebbia-show-md {
  display: block !important;
}

.bebbia-show-md-flex {
  display: flex !important;
}

.bebbia-hide-md {
  display: none !important;
}

.bebbia-hide-md-flex {
  display: none !important;
}

/* texts that are align to left in mobile and centered in desktop */
.bebbia-text-align-center-left {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .bebbia-show-md {
    display: none !important;
  }
  .bebbia-show-md-flex {
    display: none !important;
  }

  .bebbia-hide-md {
    display: block !important;
  }

  .bebbia-hide-md-flex {
    display: flex !important;
  }

  .bebbia-text-align-center-left {
    text-align: left;
  }
}

/*-----------------------------------------------end BEBBIA--------------------------------------------------------------------*/

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

/* @media screen and (max-width: 767px) {
  .product-detail-grid-card-1 {
    width: 47%;
  }
} */

@media screen and (max-width: 992px) {
  .product-detail-grid-card-1 {
    width: 47%;

    /* margin-left: auto; */
  }

  .hide-lg {
    display: block;
  }
}

@media screen and (max-width: 768) {
  .hide-md {
    display: block;
  }
}

/*---------------------- ag-grid custom css --------------*/

/* add line under header text */
.ag-header-cell-sorted-asc > div > span {
  border-bottom: 8px solid #165eeb !important;
}
.ag-header-cell-sorted-asc > div > span:first-child {
  padding-top: 9px;
  padding-bottom: 2px;
}
.ag-header-cell-sorted-asc > div > span:last-child {
  padding-top: 13.5px;
  padding-bottom: 7px;
}

.ag-header-cell-sorted-desc > div > span {
  border-bottom: 8px solid #165eeb !important;
}

.ag-header-cell-sorted-desc > div > span:first-child {
  padding-top: 9px;
  padding-bottom: 2px;
}
.ag-header-cell-sorted-desc > div > span:last-child {
  padding-top: 13.5px;
  padding-bottom: 7px;
}
/* END add line under header text */
/* modify headers */
.ag-pivot-off .ag-header-cell-label {
  color: #ffffff;
}

.ag-header-viewport {
  background-color: #002554;
}

/* remove column indicator */
/* .ag-ltr .ag-header-cell-resize {
  display: none;
} */

.ag-theme-alpine {
  --ag-background-color: #f7f7f7 !important;
}

.ag-ltr .ag-sort-indicator-icon {
  padding-left: 10px !important;
}

.ag-right-aligned-header .ag-header-cell-label {
  flex-direction: row !important;
}
/*--------------------------------------------------------*/

/*----------------MUI AUTOCOMPLETE--------------------------------*/
/* .MuiAutocomplete-input{
  background-color: red !important;
} */

/* .MuiAutocomplete-root{
  border: 2px solid red !important;
  border-radius: 50% !important;
} */

.MuiAutocomplete-endAdornment {
  display: none !important;
}

.MuiInputBase-root {
  border-radius: 19px !important;
}
@media screen and (max-width: 768px) {
  .ag-paging-panel {
    justify-content: space-between !important;
  }

  .ag-paging-page-size {
    margin: 0 !important;
  }

  .ag-paging-row-summary-panel {
    margin: 0 !important;
  }

  .ag-paging-page-summary-panel {
    margin: 0 !important;
  }
}

.ag-ltr .ag-sort-indicator-icon {
  padding-left: 10px !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-paging-row-summary-panel {
  display: none;
}

.ag-icon .ag-icon-first {
  display: none !important;
}

/* .ag-paging-panel {
  display: none !important;
} */

/* add this to a column in columnDefs with headerClass prop to align header to the left */
.ag-grid-header-text-align-left
  > .ag-header-cell-comp-wrapper
  > .ag-cell-label-container
  > .ag-header-cell-label
  > .ag-sort-indicator-container {
  margin-right: auto;
}
